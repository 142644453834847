::after, ::before {
  box-sizing: border-box;
}
.App {
  font-family: sans-serif;
  text-align: center;
}
.pdf {
  width: 612px;
  height: auto;
  background-color: white;
  margin: 0 auto;
}
body{
margin: 0;
font-size: 16px;
font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

/* ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgb(193 193 193); 
} */

#wraper{
  display: flex;
  align-items: stretch;
  height: 100vh;
}

.sticky-footer-wrapper{
  min-height:70vh;
  overflow-y: auto;
  /* display: inline-grid; */

}

a{
  text-decoration: none;
}
a:hover{
  text-decoration: none;
}

h1{
  font-weight: 600;
  text-align: center;
  padding: 5px;
  cursor: pointer;
  font-size: 29px;
  position: relative;
  /* background: none;   */
  background: linear-gradient(to right, #1cc91c,rgb(84, 128, 241),rgb(217, 48, 191)); 
  /* background-size: 200% auto; */
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  overflow: visible;
  animation: shine 1s linear infinite;
}
/* @keyframes shine{
  100%{
    background-position: 100% center;
  } 
*/

#sidebar {
  /* padding: 10px 20px 0 20px; */
  margin-bottom: -10px;
}

.header-MenuIcon {
  display: none;  
}

.header-imageMobile {
  display: none;
  position: absolute;
  top: 5px; 
}

.header_add {
  float: left;
  position: absolute;
  top: 12px;
  /* right: 0px;  */
}
.header-name {
  display: block;
}
.header-icon {
  display: none;
}

.content {
  background: rgb(249, 249, 252);
  transition: all 0.3s;
  width: 100%;
}
/* loader */
.loading {
  top: 46%;
  left: 50%;
  /* align-items: center; */
  /* background-color: #1D1F20; */
  /* height: 65vh; */
  /* justify-content: center; */
  /* text-align: center; */
  /* margin: auto;
  display: flex;  
  align-items: center;
  justify-content: center; */
  /* width: 60vw; */
  /* height: 80vh; */
  position: absolute;
}
/* .loading {
  align-items: center;
  display: flex;
  height: 65vh;
  justify-content: center;
} */
.loader  {
  animation: rotate 1s infinite;
  height: 50px;
  width: 50px;
}

.loader:before,
.loader:after {
  border-radius: 50%;
  content: '';
  display: block;
  height: 20px;
  width: 20px;
}
.loader:before {
  animation: ball1 1s infinite;
  background-color: #cb2025;
  box-shadow: 30px 0 0 #f8b334;
  margin-bottom: 10px;
}
.loader:after {
  animation: ball2 1s infinite;
  background-color: #00a096;
  box-shadow: 30px 0 0 #97bf0d;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
  }
  50% {
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
  }
  100% {
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
  }
}

@keyframes ball1 {
  0% {
    box-shadow: 30px 0 0 #f8b334;
  }
  50% {
    box-shadow: 0 0 0 #f8b334;
    margin-bottom: 0;
    -webkit-transform: translate(15px,15px);
    -moz-transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 #f8b334;
    margin-bottom: 10px;
  }
}

@keyframes ball2 {
  0% {
    box-shadow: 30px 0 0 #97bf0d;
  }
  50% {
    box-shadow: 0 0 0 #97bf0d;
    margin-top: -20px;
    -webkit-transform: translate(15px,15px);
    -moz-transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 #97bf0d;
    margin-top: 0;
  }
}

.row {
  margin: 0;
}

.label-text {
  padding: 12px 12px 12px 0;
  display: inline-block;
}
/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.success-class{
  color: #008000;
  font-size: 18px;
  font-weight: bold;
  text-align: center
}
.error-class{
  color: red;
  font-size: 18px;
  font-weight: bold;
  text-align: center
}

.dashboard-cards {
  margin: 20px;
  padding: 15px 30px;
  box-shadow: 0 0 14px 0 rgba(53,64,82,.05);
  background-color: #fff;
}

.table {
  border: 0.1px solid #80808030;
}

.table th {
  font-size: 13px;
  text-align: left;
  font-family: Georgia, 'Times New Roman', Times, serif;
}

/* .table-responsive{
  height:500px;  
  overflow:scroll;
}
 thead tr:nth-child(1) th{
    background: #f7f3f0 !important;
    position: sticky;
    top: 0;
    /* z-index: 10; 
  } */

.card-header{
  padding: 0rem 1.25rem;
}

.table-header{
  background-color: #f7f3f0 !important;

}

.table td {
  vertical-align: inherit;
  font-size: 12px;

}

.btn-listResponsive,.btn-listResponsive-sales {
  float: right;
  padding: 3px;
}

hr {
  margin-top: 0rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0,0,0,.1);
}

/* react table header color */
.ReactTable .rt-thead .rt-resizable-header {
  height: 40px;
  background-color:rgb(35, 47, 62) !important;
  color: white;
 }

.ReactTable .rt-thead .rt-resizable-header-content {
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 3px;
}
.ReactTable .rt-table {
  flex: auto 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  /* width: 100%; */
  /* border-collapse: collapse; */
  overflow: auto;
}
div.-sort-asc::after {
  content: " ";
  color: red;
  float: right;
  border: 1.5px solid #F2AA4CFF;
  width: 100%;
  font-size: 15px;
  margin-top:-25px;
}
div.-sort-desc::after{
content: "";
color: red;
border: 1.5px solid #F2AA4CFF;
float: right;
width: 100%;
margin-top: 10px;
}
.PrivateTabIndicator-colorPrimary-107 {
  background-color: #F2AA4CFF;
}
.PrivateTabIndicator-colorSecondary-107{
  background-color: #F2AA4CFF;
} 


/* @media (max-width: 599.95px) {
.MuiTabs-scrollButtonsDesktop {
   display: none; 
}
} */
.MuiTab-wrapper{
  color: #3f51b5;
} 
/* <!---------------------- Scrollbar Styles !----------------------------------------->
/* width to all pages 
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  overflow-x: auto;
}


/* Track 
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle 
::-webkit-scrollbar-thumb {
  background: #888; 
}
*/

[data-toggle="collapse"] .fa:before {  
  font-family: "FontAwesome";
  content: '\f107';
  font-weight: 400;
  font-size: 15px;
  color: black;
}

[data-toggle="collapse"].collapsed .fa:before {
  font-family: "FontAwesome";
  content: "\f105";
  font-weight: 400;
  font-size: 15px;
  color:OrangeRed
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance:textfield;
}

/* Changes overide for Autocomplete Input field*/
.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding: 2px;
  padding-right: 62px;
}

.table_td {
  text-align: center;
}

.table_th {
  text-align: center;
}

/* scrollable  */

.freeze-pane-left-header {
  position: sticky;
  position: -webkit-sticky;
  background-color: #343a40;
  left: -1px;
  font-family: Poppins;
}
.freeze-pane-left-header1 {
  position: sticky;
  position: -webkit-sticky;
  background-color: #343a40;
  left: 98px;
  font-family: Poppins;
}
.freeze-pane-left {
  position: sticky;
  position: -webkit-sticky;
  background-color: #ebeced;
  left: -1px;
  font-family: Poppins;
}
.freeze-pane-left1 {
  position: sticky;
  position: -webkit-sticky;
  background-color: #ebeced;
  left: 98px;
  font-family: Poppins;
  box-shadow: black 0px 0px 5px -3px inset;
}
.freeze-pane-right-header {
  position: sticky;
  position: -webkit-sticky;
  background-color: #343a40;
  right: -1px;
  font-family: Poppins;
}
.freeze-pane-right {
  position: sticky;
  position: -webkit-sticky;
  background-color: #ebeced;
  right: -1px;
  box-shadow: black 0px 0px 5px -3px inset;
  font-family: Poppins;
}



/*=============================================
=                   New CSS                   =
=============================================*/
:root {
  --main_saii_theme_color: #ec7833;
  --main_sdam_theme_color: #ec7833;
  --main_theme_dark_color: #672600;
  --drawer_sdam_background: #54433a;
  --drawer_saii_background: #f5f5f5;
  --drawer_saii_logo_background: #ffffff;
  --drawer_logo_background: #fff6ee;
  font-family: Poppins;
}

h4{
  padding-top: 5px;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53;
  letter-spacing: normal;
  text-align: left;
  color: #48475c;
}

h5{
  padding-top: 5px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #483b34;
}

.left_block_login {
  /* background-image:linear-gradient(139deg, var(--main_sdam_theme_color) 3%, var(--main_theme_dark_color) 96%); */
  background-image:
    linear-gradient(139deg, rgba(236, 120, 51, 0.78) 3%, rgba(103, 38, 0, 0.78) 96%),
    url('images/coal-background1.2.jpg');
  background-size: cover;
  opacity: 0.9;
}

.left_block_login_heading {
  position: relative;
  left: -8%;
  margin: auto;
  width: 400px;
  height: 126px;
  font-family: Poppins;
  font-size: 35px;
  font-weight: 500;
  /* border: 1px solid rgb(80, 80, 83); */
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
}

.left_block_login_text {
  width: 401px;
  height: 47px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
}

.right_block_login_container {
  float: right;
  width: 50%;
}

.right_block_login {
  margin: auto;
  width: 400px;
  height: 440px;
  border-radius: 14.5px;
  box-shadow: 0 0 5px 0 rgba(0, 39, 77, 0.1);
  background-color: #ffffff;
  position: relative;
  top: 100%;
  margin-top: 30%;
  padding: 30px;;
}

.right_block_login_logo {
  margin: auto;
  width: 220px;
  height: auto;
}

.mobile_header_logo {
  margin: auto;
  width: 100px;
  height: auto;
}

.sidebar_header_logo {
  margin: auto;
  width: 100px;
  height: auto;
  margin: 12px;
}

.right_block_login_logo_container {
  margin: auto;
  display: flex;
}

.sidebar_logo_container {
  margin: auto;
  display: inline-block;
  position: relative;
  left: -55px;
}

.next_heading {
  height: 14.5px;
  font-family: Poppins;
  font-weight: 500;
  font-size: 21px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.48;
  color: #575962;
  display: block;
  text-align: center;
  margin: 15px 0px;
}

.forget_password {
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: var(--main_sdam_theme_color);
  margin-top: 20px;
  display: block;
}

.signin_button {
  border-radius: 2.5px;
  background-color: var(--main_sdam_theme_color);
  border-color: var(--main_sdam_theme_color);
}

.signin_text {
  width: 140px;
  height: 52px;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.login_error_message {
  background-color:white;
  color:red;
  margin-top: 5px;
}

.sidebar-header-saii {
  background-color: var(--drawer_saii_logo_background);
  height: 54px;
}

.sidebar-header-sdam {
  background-color:var(--drawer_logo_background);
  height: 54px;
}

#sidebar_sdam {
  min-width: 266px;
  display: block;
  max-width: 270px;
  font-family: Poppins;
  overflow: auto;
  background-color: var(--drawer_sdam_background);
  height: 100vh;
  color: rgba(238, 238, 238, 0.7);
  transition: all 0.3s;
}

#sidebar_saii {
  min-width: 266px;
  display: block;
  max-width: 270px;
  font-family: Poppins;
  overflow: auto;
  background-color: var(--drawer_saii_background);
  height: 100vh;
  color: rgba(238, 238, 238, 0.7);
  transition: all 0.3s;
}

.sidebar_drawer_sdam {
  min-width: 239px;
  display: block;
  max-width: 270px;
  font-family: Poppins;
  overflow: auto;
  background-color: var(--drawer_sdam_background);
  height: 100vh;
  color: rgba(238, 238, 238, 0.7);
  transition: all 0.3s;
}

.sidebar_drawer_saii {
  min-width: 239px;
  display: block;
  max-width: 270px;
  font-family: Poppins;
  overflow: auto;
  background-color: var(--drawer_saii_background);
  height: 100vh;
  color: rgba(238, 238, 238, 0.7);
  transition: all 0.3s;
}

ul {
  padding: 0px 0px 5px 0px;
}

.listIcons {
  opacity: 0.5;
  color: rgb(238, 238, 238);
}

.list-text {
  font-size: 14px;
  font-weight: 400;
  font-family: Poppins;
  cursor: pointer;
  letter-spacing: 0.01em;
  margin-left: 18px;
}

.list_text {
  font-size: 14px;
  font-weight: 400;
  font-family: Poppins;
  cursor: pointer;
  letter-spacing: 0.01em;
  margin-left: 18px;
  /* color: rgb(244, 244, 244); */
}

.list-text:hover {
  color: rgba(238, 238, 238, 0.9);
}

.list_text:hover {
  color: var(--main_saii_theme_color);
}

.drawer_links {
  padding-left: 16px;
  padding-top: 0px;
  padding-bottom: 0px;
  height: 46px;
}

.sidebar_sub_heading {
  width: 82px;
  height: 19px;
  font-family: Poppins;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: 0.26px;
  text-align: left;
  color: #b5866e;
  margin: 16px 18px;
}

.drawer_links:hover {
  color: rgba(238, 238, 238, 0.9);
}

.drawer_sub_links {
  padding-left: 40px;
  padding-top: 0px;
  padding-bottom: 0px;
  height: 42px;
}

.icon_color {
  color: white;
}

.main_header {
  padding: 27px;
  margin: 0;
  border-radius: 0;
  box-shadow:0 0 14px 0 rgba(53,64,82,.05);
  background-color: #ffffff;
}

.header_button {
  border-radius: 3px;
  background-color: var(--main_saii_theme_color);
  border: var(--main_saii_theme_color);
  margin-top: -5px;
}

.header_button_text {
  width: 70px;
  height: 35px;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11;
  letter-spacing: normal;
  text-align: center;
  color: white;
}

.login_short_icon {
  color: rgba(10, 187, 135, 0.59);/**rgba(10, 187, 135, 0.59);*/
  font-size: 26px;
  padding: 2px;
  background-color: rgba(10, 187, 135, 0.59);/**rgba(10, 187, 135, 0.59);*/
  border-radius: 4px;
}

.contact_short_icon {
  color: #000000;
  font-size: 20px;
  padding: 10px;
  background-color: #f8d58b;
  border-radius: 25px;
  height: 45px;
  width: 45px;
  text-align: center;
  text-transform: uppercase;
  font-family: Poppins;
}

.contact_details {
  margin-left: 10px;
  text-align: left;
  font-family: Poppins;
}

.login_short_mes {
  margin-left: 10px;
  font-size: 14px;
}

.login_name {
  text-transform: capitalize;
  color: #6c7293;
  font-size: 16px;
}

.Stockholm-icons--Navigation--Angle-double-left {
  width: 24px;
  height: 24px;
  object-fit: contain;
  float: right;
  /* position: relative; */
  top: 14px;
  right: 14px;
}

.content-header {
  padding: 0;
  /* margin-top: -12px; */
  background-color: white;
  min-height: 50px;
}

.page_heading {
  padding: 10px 20px;
  margin: 0px;
}

.table_header {
  width: 350px;
  text-transform: uppercase;
  color: rgb(108, 114, 147);
  font-size: 16px;
  font-weight: 500;
}

.container-login{
  padding: 20px;
  margin-left:auto;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 10%;
  background-color: #fff;
  border-radius: 0.5rem;
}

.container{
  height:78vh;
  width: 100%;
  background-color: #f0ede9;
  overflow: auto;
  max-height: 89vh;
  padding-bottom: 2vh;
  /* height: 87%; */
  font-family: Poppins;
  display: inline-grid;
}

.card {
  padding: 0px;
  border: none;
  /* margin-top: 7px; */
  padding: 16px;
}

.view_link {
  text-decoration: none;
  color: var(--main_sdam_theme_color);
  max-width: 200px;
  font-family: Poppins;
}

.edit_botton_link {
  text-transform: capitalize;
  min-height: 2px;
  height: 35px;
  color: var(--main_sdam_theme_color);
  font-family: Poppins;
}

.add_button_adjustment {
  position: relative;
  top: -12px;
  height: 30px;
}

.MuiTableCell-root .MuiButton-root {
  font-family: Poppins;
}

.sidebar_icons {
  width: 24px;
  height: 24px;
  /* margin-left: 10px; */
}

.popup_name {
  display: block;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  color: #9f8e84;
  font-family: Poppins;
}

.popup_block {
  width: 150px;
  height: 154px;
  margin: 0px 10px;
  text-align: center;
  padding-top: 30px;
  font-family: Poppins;
}

.popup_block:hover{
  border: var(--main_sdam_theme_color) solid 0.5px;
  color: var(--main_sdam_theme_color);
  /*var(--main_sdam_theme_color)*/
}
.popup_block:hover img {
  filter: grayscale(100%) sepia(100%);
}
.popup_block:hover .popup_name {
  color: var(--main_sdam_theme_color);
}

.saii_icon {
  filter: invert(55%) sepia(12%) saturate(618%) hue-rotate(337deg) brightness(87%) contrast(79%);
  /* background-color: #6f5e5e1a; */
}

.popup_title {
  width: 450px;
  height: 23px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: left;
  color: #575962;
}

/**
*
* Stevedore
*
*/
.type_1 {
  background-color: rgba(65, 193, 151, 0.12);
  padding: 10px;
  height: 20px;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 600;
  color: rgb(65, 193, 151);
  font-family: Poppins;
}
/**
*
* Barge
*
*/
.type_2 {
  background-color: rgba(93, 120, 255, 0.12);
  padding: 10px;
  height: 20px;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 600;
  color: rgb(93, 120, 255);
  font-family: Poppins;
}
/**
*
* Floating Crane
*
*/
.type_3 {
  background-color: rgba(248, 191, 69, 0.12);
  padding: 10px;
  height: 20px;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 600;
  color: rgb(248, 191, 69);
  font-family: Poppins;
}
/**
*
* Surveyor
*
*/
.type_4 {
  background-color: rgba(246, 92, 141, 0.12);
  padding: 10px;
  height: 20px;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 600;
  color: rgb(246, 92, 141);
  font-family: Poppins;
}
/**
*
* Vessel Agent
*
*/
.type_5 {
  background-color: rgba(248, 191, 69, 0.12);
  padding: 10px;
  height: 20px;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 600;
  color: rgb(248, 191, 69);
  font-family: Poppins;
}
/**
*
* Coal
*
*/
.type_6 {
  background-color: rgba(167, 167, 167, 0.12);
  padding: 10px;
  height: 20px;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 600;
  color: rgb(167, 167, 167);
  font-family: Poppins;
}
/**
*
* Others
*
*/
.type_7 {
  background-color: rgba(224, 130, 63, 0.12);
  padding: 10px;
  height: 20px;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 600;
  color: rgb(224, 130, 63);
  font-family: Poppins;
}

.stepper_icon_section {
  padding: 20px;
  text-align: center;
}

.stepper_icon {
  font-size: 86px;
  color: #1FA1E3;
}

.stepper_sub_icon {
  position: relative;
  top: 22px;
  color: #1FA1E3;
}

.number_count {
  color: rgb(96, 102, 122);
  font-size: 13px;
  margin-left: 8px;
  padding: 4px 8px;
  background: #F5F5F5;
  border-radius: 4px;
}

.section_block {
  min-height: 64vh;
  width: 70%;
  margin: auto;
}

.section_block_adjusted {
  min-height: 64vh;
  min-width: 70%;
  margin: auto;
}

.form_label {
  color: #6c7293;
  font-family: Poppins;
  font-size: 14px;
  margin-bottom: 6px;
}

.form_section_label {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: rgba(72, 59, 52, 0.7);
}

.form_heading_label {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #48475c;
}

.previous_button {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #5c6171;
  /* position: absolute; */
  bottom: 20px;
  background-color: #fff;
  border-color: grey;
}

.btn-success {
  position: absolute;
  bottom: 16px;
  right: 15px;
  color: #ffffff;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  border-radius: 5px;
}
  .next_button {
    position: absolute;
    bottom: 16px;
    right: 15px;
    color: #ffffff;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    border-radius: 5px;
    background-color: var(--main_sdam_theme_color);
    border-color: var(--main_sdam_theme_color);
  }
.payment_button {
  /* position: absolute; */
  bottom: 16px;
  right: 15px;
  color: #ffffff;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  border-radius: 5px;
  background-color: var(--main_sdam_theme_color);
  border-color: var(--main_sdam_theme_color);
}



.MuiFab-secondary {
  color: #fff;
  background-color:var(--main_sdam_theme_color);
}

.bottom_buttons_section {
  position: relative;
  border-top: 1px #d8d5d5 solid;
  padding: 16px;
  /* margin: 10px -16px -16px -16px; */
  /* left: -4px; */
  background: white;
  height: 70px;
}

.section_background {
  border-radius: 5px;
  background-color: #ececec;
  padding: 15px;
}

.section_background_contract {
  background-color: #f4f2f0;
  padding: 15px;
}

.section_add_contact {
  width: 250px;
  height: 77px;
  border-radius: 5px;
  border: dashed 1px var(--main_sdam_theme_color);
  background-color: #ffffff;
  padding: 10px;
  text-align: center;
  cursor: pointer;
}

.border_dashed {
  border-radius: 5px;
  border: dashed 1.5px #dddddd;
}
.border_view {	
  border-radius: 5px;	
  border: dashed 1px rgb(72 59 52 / 0.25);	
  padding: 16px;	
}

.border_view {
  border-radius: 5px;
  border: dashed 1px rgb(72 59 52 / 0.25);
  padding: 16px;
}

.border_dotted {
  border-radius: 3px;
  border : dotted 1px var(--main_sdam_theme_color);
}

.section_add_contact_name {
  font-family: Poppins;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--main_sdam_theme_color);
  display: block;
  margin-top: 10px;
}

.place_holder_text {
  font-family: Poppins;
  background-color: white;
  text-transform: uppercase;
  font-size: 14px;
}

.addrow_button_adjustment {
  width: 150px;
  background-color: white;
  border: var(--main_sdam_theme_color) 0.5px solid;
}

.payment_button_adjustment {
  width: 150px;
  border: var(--main_sdam_theme_color) 0.5px solid;
}

.icon_hover:hover {
  background: #fff6ee;
  border-radius: 40px;
}

.section_header {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #483b34;
  background-color: #f7f3f0;
}

.section_block_seperator {
  padding: 10px;
  margin-bottom: 24px;
  box-shadow: 0 0 5px 0 rgba(0, 39, 77, 0.1);
  /* box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 1px 0px rgba(0,0,0,0.14), 0px 0px 1px 0px rgba(0,0,0,0.12); */
}

.view_name_lable {
  opacity: 0.7;
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #656c9b;
  display: block;
}

.view_data_label {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #495057;
  font-family: Poppins;
  text-transform: uppercase;
}

.contact_details_name_view {
  margin: 0px 12px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #48475c;
  text-transform: uppercase;
}

.contact_details_view {
  font-family: Poppins;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: left;
  color: #9397a6;
  text-transform: uppercase;
}

/* Override material ui table title css */

.MTableToolbar-root-113 {
  padding-left: 0px;
}

.MuiPaper-elevation1 {
  box-shadow: 0 0 5px 0 rgba(0, 39, 77, 0.1);
}

.MuiInputBase-root {
  font-family: Poppins;
}

.drop_down_list {
  font-family: 'Poppins';
  font-size: 12px;
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
  font-family: 'Poppins';
}

.table thead th {
  vertical-align: bottom;
  /* border-bottom: 2px solid #dee2e6; */
  font-family: Poppins;
  /* padding: 14px; */
  text-align: left;
}

*:focus {
  outline: none !important;
}

abbr[data-original-title], abbr[title] { 
  display: inline;
  text-decoration: none;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding: 2px;
}

.remove_icon {
  width: 35px;
  height: 0px;
  position: absolute;
  right: -50px;
  top: 30px;
}

.contract_display_header_label {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: rgba(72, 59, 52, 0.5);
  display: block;
}

.contract_display_header_value {
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #483b34;
  display: block;
}

.specs_button {
  min-width: 100px;
  height: 20px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #7f7571;
}

.tab_button {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #7f7571;
}

.active_status {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.86;
  letter-spacing: normal;
  text-align: left;
  color: #1aa0e2;
  text-transform: capitalize;
}

.freezed_status {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.86;
  letter-spacing: normal;
  text-align: left;
  color: #ffbc2b;
  text-transform: capitalize;
}

.completed_status {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.86;
  letter-spacing: normal;
  text-align: left;
  color: #1cbb87;
  text-transform: capitalize;
}

.cancel_status {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.86;
  letter-spacing: normal;
  text-align: left;
  color: #fd397b;
  text-transform: capitalize;
}

.dot_align {
  font-size: 8px;
  vertical-align: middle;
}

.drawer_section_label {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: rgba(72, 59, 52, 0.5);
  display: block;
}

.drawer_section_value {
  font-family: Poppins;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
  text-align: left;
  color: #483b34;
  display: block;
}

.drawer_section_total {
  opacity: 0.7;
  font-family: Poppins;
  font-size: 8px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #42352d;
}

.drawer_section_total_value {
  font-family: Poppins;
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #42352d;
  padding-left: 6px;
}

.drawer_table_header_label {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: rgba(72, 59, 52, 0.5);
  text-transform: uppercase;
}

.drawer_table_value {
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #483b34;
}

.drawer_quantity_allocation {
  position: relative;
  display: block;
  top: -9px;
  left: -11px;
  padding: 0px 5px;
  background-color: #cec5c1;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #42352d;
}

.quantity_allocation {
  position: relative;
  /* display: inherit; */
  top: -6px;
  left: -11px;
  padding: 0px 5px;
  background-color: #cec5c1;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #42352d;
}

.table_header_label {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: rgba(72, 59, 52, 0.5);
  text-transform: uppercase;
  padding-left: 10px;
}

.drawer_button {
  border-radius: 3px;
  background-color: #31c197;
  border: #31c197;
}

.drawer_btn_success {
  position: absolute;
  bottom: 16px;
  right: 15px;
  border-radius: 3px;
  background-color: #31c197;
  border: #31c197;
}

.background_color {
  background-color: var(--main_saii_theme_color);
  border: var(--main_saii_theme_color);
}

.drawer_text {
  width: 100px;
  height: 35px;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11;
  letter-spacing: normal;
  text-align: center;
  color: white;
}

.drawer_cancel_button {
  border-radius: 5px;
  border: solid 1px #efefef;
}

.drawer_cancel_text {
  width: 100px;
  height: 35px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: center;
  color: #5c6171;
}

.drawer_calculate_button {
  position: absolute;
  left: 15px;
}

.drawer_calculate_adjustment {
  background-color: white;
  border: green 0.5px solid;
  color: green;
}

.table_value {
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #483b34;
  padding-left: 10px;
}

table {
  border-collapse: collapse;
}

/* tbody:before {
  content:"@";
  display:block;
  line-height: 5px;
  text-indent:-99999px;
} */

.purchase_contract {
  position: relative;
  top: -10px;
  display: block;
}

.left_arrow_adjst {
  font-size: 24px;
  color: rgb(75, 99, 111);
  cursor: pointer;
  padding-right: 15px;
  /* vertical-align: middle; */
  -webkit-text-stroke: 2px white;
}

.sub_heading_label {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #483b34;
}
.drawer_label {	
  font-family: Poppins;	
  font-size: 12px;	
  font-weight: normal;	
  font-stretch: normal;	
  font-style: normal;	
  line-height: 1.75;	
  letter-spacing: normal;	
  text-align: left;	
  color: rgba(72, 59, 52, 0.7);	
  margin-bottom: 8px;	
}	
.drawer_sub_label {	
  font-family: Poppins;	
  font-size: 8px;	
  font-weight: normal;	
  font-stretch: normal;	
  font-style: normal;	
  line-height: 1.5;	
  letter-spacing: normal;	
  text-align: left;	
  color: rgba(72, 59, 52, 0.7);	
}

.drawer_label {
  font-family: Poppins;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  text-align: left;
  color: rgba(72, 59, 52, 0.7);
  margin-bottom: 8px;
}

.drawer_sub_label {
  font-family: Poppins;
  font-size: 8px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: rgba(72, 59, 52, 0.7);
}

.nogap > .col{ padding-left:7.5px; padding-right: 7.5px}
.nogap > .col:first-child{ padding-left: 15px; }
.nogap > .col:last-child{ padding-right: 15px; }

.access_sub_heading {
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  color: rgba(72, 59, 52, 0.5);
  display: block;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  font-family: Poppins;
}

.access_title {
  font-size: 16px;
  display: block;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  font-family: Poppins;
  color: #483a34;
  cursor: pointer;
}

.access_sub_title {
  font-size: 12px;
  font-weight: 500;
  color: rgba(72, 59, 52, 0.5);
  display: block;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  font-family: Poppins;
}

.popover_class_10::before{
  content: '';
  position: absolute;
  top: -15px;
  left: 10%;
  border-bottom: 10px solid #ec7833;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  border-top: 10px solid transparent;
  z-index: 10;
}

.popover_class_50::before{
  content: '';
  position: absolute;
  top: -15px;
  left: 50%;
  border-bottom: 10px solid #ec7833;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  border-top: 10px solid transparent;
  z-index: 10;
}

.popover_class_90::before{
  content: '';
  position: absolute;
  top: -15px;
  left: 90%;
  border-bottom: 10px solid #ec7833;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  border-top: 10px solid transparent;
  z-index: 10;
}

.expansion_title{
  font-family: Poppins;
  font-size: 14px;
  font-stretch: normal;
  color: #42342d;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
}

.checkbox_title{
  font-family: Poppins;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: rgb(66 52 45);
}

/*=============================================
=                 Stepper Icons               =
=============================================*/

.stepper {
  text-align: center;
  display: block;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.05);
  background-color: #f8f8f8;
  position: sticky;
  top: 0;
  z-index: 50;
}

.stepper_icon_section_part {
  min-width: 260px;
  flex-direction: row;
  display: inline-flex;
  justify-content: space-evenly;
}

.stepper_text {
  padding-left: 20px;
  font-size: 14px;
  display: block;
}

.stepper_arrow {
  top: 4px;
  margin-left: 20px;
}

#table_header {
  color: #b5866e;
  font-size: 16px;
  font-weight: 400;
  min-width: 225px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
}

#table_sub_header {
  color: #ec7733;
  font-size: 16px;
  font-weight: 400;
  min-width: 225px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
}


.MuiTableCell_root {
  padding: 7px 16px;
}

.MuiTableRow-root {
  padding: 6px !important;
}

.excel_icon {
  color: #ec7733;
  font-size: 14px;
  cursor: pointer;
  margin-left: 8px;
  vertical-align: middle;
  background: #fff7f2;
  padding: 8px;
  border-radius: 2px;
}

.table_header_barge_label {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.75;
  letter-spacing: normal;
  text-align: left;
  color: rgba(72, 59, 52, 0.5);
}

.edit_icon {
  font-size: 14px;
  color: var(--main_saii_theme_color);
  cursor: pointer;
  vertical-align: middle;
  padding: 8px;
  background: #fff7f2;
  border-radius: 50px;
  margin-left: 25px;
}

.edit_icon:hover {
  text-decoration: none;
  color: green;
  background: #fff7f2;
}

.allocation_table_header_label {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.86;
  letter-spacing: normal;
  text-align: left;
  color: rgba(72, 59, 52, 0.5);
  text-transform: uppercase;
  padding-bottom: 15px;
}

.linear_allocation_label {
  font-family: Poppins;
  font-size: 8px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: rgba(72, 59, 52, 0.5);
  /* display: block; */
  padding-top: 3px;
  padding-right: 4px;
}

.linear_allocation_value {
  font-family: Poppins;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
  text-align: left;
  color: #483b34;
  display: flex;
}

.allocation_box_shadow {
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05);
}

.popper_label{
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.86;
  letter-spacing: normal;
  text-align: left;
  color: #42352d;
  
}

.payment-modal{
  min-width: 800px;
  margin-left: -40px;
}

.please-enter-the-increase-quantity-number {
  width: 172px;
  height: 11px;
  font-family: Poppins;
  font-size: 8px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: rgba(72, 59, 52, 0.7);
}

.allocation_modal {
  width: 65%;
  margin-left: 10%;
}

.addquality_adj {
  width: 100px;
  margin: 5px;
  position: absolute;
  right: 10px;
  top: 4px;
}

.table_label_adj {
  font-size: 16px;
  text-transform: uppercase;
}

.allocation_value{
  position: relative;
  display: block;
  top: -10px;
  left: -10px;
  background-color: #cec5c1;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #42352d;
  width: 50px;
  border-radius: 0 0 5px 0;
  text-align: center;
  padding: 6px;
}

.allocation_table_value {
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.86;
  letter-spacing: normal;
  text-align: left;
  color: rgba(72, 59, 52, 0.7);
  padding: 10px;
}

.adornment_background {
  background: #f2efeb;
  min-height: 35px;
  padding: 8px;
  margin: 0px;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}

.accordion_collapse {
  border: 1px solid rgba(72, 59, 52, 0.2);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.access_accordion_collapse {
  border: 1px solid #9c948f;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
/*=============================================
=                   Media Query                   =
=============================================*/


@media (max-width: 320px)  {
  .container-login{
    margin: auto;
  }
  .col-md {
    padding: 5px;
  }  
  .container {
    padding: 0;
    box-shadow: none;
    overflow: auto;
  }
  h4{
    font-size: 18px;
    padding-top: 0;
  }
  hr{
    margin-bottom: 7px;
    margin-top: 0;
  }  
  .main_header {
    padding: 27px;
    margin: 0;
    border-radius: 0;
    box-shadow:0 0 14px 0 rgba(53,64,82,.05);
    background-color: #ffffff;
  }
  .header-imageMobile {
    float: left;
    display: block;
    position: absolute;
    top: 12px; 
    /* margin-left: 50px; */
    left: 40px;
  }
  .header-MenuIcon {
    float: left;
    display: block;
    position: absolute;
    top: 13px;
    left: 12px;
    font-size: 20px;
  }
  .header_add {
    float: left;
    position: absolute;
    top: 13px;
    right: 60px; 
  }
  .label-text {
    padding: 0;
    margin-bottom: 0;
  }
  .content-header {
    padding: 7px 10px 0px 15px;
  }
  .btn-responsive {
    width: 100%;
    width: -moz-available;
    width: -webkit-fill-available;
  }
  .btn-listResponsive,.btn-listResponsive-sales {
    float: right;
    padding: 3px;
    margin-top: 12px;
  }
 
  .header-name {
    display: none;
  }
  .header-icon {
    display: block;
  }
  .dashboard-cards {
    margin: 7px;
  }
}


.drawer-section {
  min-height: 85vh;
  overflow-y: auto;
    
  }
  .drawer-footer {
    position: -webkit-sticky;
    position: fixed;
    bottom: -1px;
    border-radius: 2px;
    width: 815px;
  /* background-color: red; */
  /* right: 0; */
  overflow: hidden;
  font-size: 20px;
  text-align: center;

  }

@media (min-width: 321px)and (max-width:375px){
  .container-login{
    margin: auto;
  }
  .container {
    height: 77.5vh;
    box-shadow: none;
    overflow: auto;
    padding: 0;
  }
  .main_header {
    padding: 30px;
    margin: 0;
    position: relative;
    border-radius: 0;
    box-shadow:0 0 14px 0 rgba(53,64,82,.05);
    background-color: #ffffff;
  }
  .header-imageMobile {
    float: left;
    display: block;
    position: absolute;
    top: 12px; 
    /* margin-left: 50px; */
    left: 40px;
  }
  .header_add {
    float: left;
    position: absolute;
    top: 13px;
    right: 60px; 
  }
  .header-MenuIcon {
    float: left;
    display: block;
    position: absolute;
    top: 13px;
    left: 12px;
    font-size: 20px;
  }
  .label-text{
    padding: 0;
    margin-bottom: 0;
  }
  h4{
    font-size: 18px;
    padding-top: 0;
  }
  hr{
    margin-bottom: 7px;
    margin-top: 0;
    
  }  
  .btn-responsive {
    width: 100%;
    width: -moz-available;
    width: -webkit-fill-available;
  }
  .btn-listResponsive,.btn-listResponsive-sales {
    float: right;
    padding: 3px;
    margin-top: 15px;
  }  
  .header-name {
    display: none;
  }
  .header-icon {
    display: block;
  }
  .dashboard-cards {
    margin: 7px;
  }
 
}

@media (min-width:376px) and (max-width:425px) {
  .container-login{
    margin: auto;
  }  
  .container {
    padding: 0 10px;
    height: 80vh;
    overflow: auto;
  }
  .content-header {
    padding: 7px 10px 0px 10px;
  }
  h4{
    font-size: 18px;
    padding-top: 0;
  }
  hr{
    margin-bottom: 7px;
    margin-top: 0;
  }  
  .header-imageMobile {
    float: left;
    display: block;
    position: absolute;
    top: 12px; 
    /* margin-left: 50px; */
    left: 40px;
  }
  .header-MenuIcon {
    float: left;
    display: block;
    position: absolute;
    top: 13px;
    left: 12px;
    font-size: 20px;
  }
  .header_add {
    float: left;
    position: absolute;
    top: 13px;
    right: 60px; 
  }
  .header-name {
    display: none;
  }
  .header-icon {
    display: block;
  }
  .btn-listResponsive {
    float: right;
    padding: 3px;
    margin-top: 15px;
  }
  .btn-listResponsive-sales {
    float: right;
    padding: 1px;
    margin-top: 15px;
  }  
  .dashboard-cards {
    margin: 7px;
  }
}

@media screen and (max-width: 700px) {  
  .label-text {
    padding: 0;
    margin-bottom: 0;
  }
}

@media (min-width:426px) and (max-width: 768px) {  
  .container-login{
    margin: auto;
  }
  .container {
    height: 77vh;
    box-shadow: none;
    overflow: auto;
  }
  .sticky-footer-wrapper{
    min-height: 80vh;
  }   
  .content-header {
    padding: 7px 10px 0px 10px;
  }
  hr{
    margin-top: 0;
  } 
  .btn-listResponsive {
    float: right;
    padding: 1px;
    margin-top: 15px;
  }
  .btn-listResponsive-sales {
    float: right;
    padding: 1px;
    margin-top: 15px;
  }  
  .dashboard-cards {
    margin: 7px;
  }
}

@media (min-width:1200px) {
  .container {
    max-width: 1100px;
    display: inline-grid;
  }
  
  /* .sticky-footer-wrapper{
    min-height: 90vh;
  }  */
}


@media (max-width:1280px){
  .container {
    min-height: 82vh;
    max-width: 100%;
    display: inline-grid;
  }
  .sticky-footer-wrapper{
    min-height: 87vh;
  }
  .drawer-section{
    min-height: 82vh;
  }
}

@media (min-width:1440px) {
  .container {
    min-height: 85vh; 
    min-width: 100%;
    /* margin-right: 12px; */
    display: inline-grid;
  }
  .sticky-footer-wrapper{
    min-height: 88.8vh;
  }
  .drawer-section{
    min-height: 85vh;
  }
}